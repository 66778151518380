import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import supabase from "../../supabase";

const RefundPage = () => {
  const [filter, setFilter] = useState('all');
  const [startDate, setStartDate] = useState(null);
  const [orders, setOrders] = useState([]);
  const [refundedOrders, setRefundedOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    const fetchOrdersByDate = async (selectedDate) => {
      if (!selectedDate) return;
  
      // Convert the selectedDate to start of the day (00:00:00) and end of the day (23:59:59)
      const startDateTime = new Date(selectedDate);
      startDateTime.setHours(0, 0, 0, 0); // Start of the day
      const endDateTime = new Date(selectedDate);
      endDateTime.setHours(23, 59, 59, 999); // End of the day
  
      const { data: orders, error } = await supabase
        .from('orders')
        .select('*')
        .gte('created_at', startDateTime.toISOString()) // Greater than or equal to start of the day
        .lte('created_at', endDateTime.toISOString()) // Less than or equal to end of the day
        .eq('status', 'paid')
        .neq('served', true)
        .neq('scanned', true)
        .order('created_at', { ascending: false });
  
      if (error) {
        console.error(error);
      } else {
        setOrders(orders);
      }
    };
  
    if (filter === 'date' && startDate) {
      fetchOrdersByDate(startDate);
    } else if (filter === 'today') {
      const today = new Date();
      fetchOrdersByDate(today);
    } else if (filter === 'all') {
      fetchOrders();
    }
  }, [filter, startDate]);
  

  const fetchOrders = async () => {
    // Fetching latest orders (paid, not served, not scanned)
    const { data: orders, error } = await supabase
      .from('orders')
      .select('*')
      .eq('status', 'paid')
      .neq('served', true)
      .neq('scanned', true)
      .order('created_at', { ascending: false });

    if (error) {
      console.error(error);
    } else {
      setOrders(orders);
    }
  };

  const fetchRefundedOrders = async () => {
    // Fetching all refunded orders
    const { data: refundedOrders, error } = await supabase
      .from('orders')
      .select('*')
      .eq('status', 'refunded')
      .order('created_at', { ascending: false });

    if (error) {
      console.error(error);
    } else {
      setRefundedOrders(refundedOrders);
    }
  };

  const initiateRefund = async (orderId) => {
    const confirmRefund = window.confirm(`Are you sure you want to refund order ID: ${orderId}?`);

    if (confirmRefund) {
      setIsLoading(true);
      const BACKEND_URL = process.env.REACT_APP_BACKEND_ORDER_URL;
      
      try {
        const response = await fetch(`${BACKEND_URL}/auto-refund`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            orderId,
          }),
        });

        if (response.ok) {
          alert(`Refund successful for order ID: ${orderId}`);
          fetchOrders();  // Refresh the orders
          fetchRefundedOrders();  // Refresh refunded orders
        } else {
          alert(`Failed to refund order ID: ${orderId}`);
        }
      } catch (error) {
        console.error(error);
        alert(`An error occurred: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="w-full flex flex-col items-center px-4 py-8">
      <h1 className="text-3xl font-bold text-white text-center mb-6">Refund Orders</h1>

      {/* Filter Buttons */}
      <div className="flex justify-center mb-4 w-full max-w-xl space-x-4">
        <button
          className={`w-1/4 px-6 py-2 rounded-full text-white text-center transition-transform ${filter === 'today' ? 'border border-white shadow-lg scale-105' : ''}`}
          onClick={() => setFilter('today')}
        >
          Today
        </button>
        <button
          className={`w-1/4 px-6 py-2 rounded-full text-white text-center transition-transform ${filter === 'date' ? 'border border-white shadow-lg scale-105' : ''}`}
          onClick={() => setFilter('date')}
        >
          Select Date
        </button>
        <button
          className={`w-1/4 px-6 py-2 rounded-full text-white text-center transition-transform ${filter === 'all' ? 'border border-white shadow-lg scale-105' : ''}`}
          onClick={() => setFilter('all')}
        >
          All
        </button>
        <button
          className={`w-1/4 px-6 py-2 rounded-full text-white text-center transition-transform ${filter === 'refunded' ? 'border border-white shadow-lg scale-105' : ''}`}
          onClick={() => setFilter('refunded')}
        >
          Refunded
        </button>
      </div>

      {/* Date Picker */}
      {filter === 'date' && (
        <div className="flex justify-center mb-4">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="bg-white text-black p-2 rounded-lg"
          />
        </div>
      )}

      {/* Loader */}
      {isLoading && <div className="text-white mb-4">Processing refund...</div>}

      {/* Orders List */}
      <div className="flex flex-col items-center w-full">
        {filter === 'refunded' ? (
          refundedOrders.length ? (
            refundedOrders.map((order) => (
              <div key={order.id} className="bg-white/10 border-1 border-white text-white p-4 rounded-lg mb-4 w-full max-w-xl backdrop-blur-2xl">
                <p className="text-lg font-semibold">Order ID: {order.order_id}</p>
                <p className="text-lg">Refunded On: {new Date(order.refund_date).toDateString()}</p>
                <p className="text-lg">Total: Rs {order.amount.toFixed(2)}</p>
              </div>
            ))
          ) : (
            <p className="text-white text-lg">No refunded orders available</p>
          )
        ) : (
          orders.length ? (
            orders.map((order) => (
              <div key={order.id} className="bg-white/10 border-1 border-white text-white p-4 rounded-lg mb-4 w-full max-w-xl backdrop-blur-2xl">
                <div className="flex flex-col md:flex-row md:justify-between items-center border-b border-gray-600 pb-4 mb-4">
                  <div className="md:w-1/2 text-center md:text-left">
                    <p className="text-lg font-semibold">Order ID: {order.order_id}</p>
                    <p className="text-lg">{new Date(order.created_at).toLocaleString()}</p>
                  </div>
                  <div className="md:w-1/2 text-center md:text-right mt-4 md:mt-0">
                    <p className="font-semibold">Items: </p>
                    <div className="flex flex-col w-full px-2">
                      {Object.keys(order.items).map((item) => (
                        <div key={order.items[item].id} className="flex justify-between w-full gap-20">
                          <p>{item}</p>
                          <p>Rs. {order.items[item].cost.toFixed(2)}</p>
                        </div>
                      ))}
                    </div>
                    <p className="font-semibold">Total: Rs {order.amount.toFixed(2)}</p>
                  </div>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <p className={`text-lg ${order.status === 'unserved' ? 'text-red-500' : 'text-green-500'}`}>
                    Status: {order.status}
                  </p>
                  <button
                    onClick={() => initiateRefund(order.order_id)}
                    className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg shadow-md"
                    disabled={isLoading}
                  >
                    Initiate Refund
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-white text-lg">No orders available</p>
          )
        )}
      </div>
    </div>
  );
};

export default RefundPage;
