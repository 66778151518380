import React, { useContext, useEffect, useState } from "react";
import { TfiAngleDoubleDown, TfiAngleDoubleUp } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import ProfilePhoto from "../../components/ProfilePhoto";
import { SessionContext } from "../../components/SessionContext";
import MenuIcon from "../../static/menu-edit.png";
import OrderIcon from "../../static/ordericon.png";
import Qrcode from "../../static/qrcode.png";
import UserIcon from "../../static/user-edit.png";
import supabase from "../../supabase";
import "./portal.css";
import Statistics from "../../static/statistics.png";

async function fetchDishes(setMenu) {
  const { data: dishes, error } = await supabase.from("menu").select("*");
  if (error) {
    console.error(error);
  } else {
    setMenu(dishes);
    console.log(dishes);
  }
}

const CountDisplay = ({ itemName, count,stockLimit }) => {
  return (
    <div className="count-display">
      <span className="h-10 flex flex-col items-center justify-center">{itemName}</span>
      <span
        className="available-item-count"
        style={{ color: count <= stockLimit ? "red" : "white" }}
      >
        {count}
      </span>
      <span>remaining</span>
    </div>
  );
};

const AvailableItems = () => {
  const [showAll, setShowAll] = useState(false);
  const [containerHeight, setContainerHeight] = useState("auto"); // State variable for container height
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    fetchDishes(setMenu);
  }, []);

  useEffect(() => {
    const menuChannel = supabase
      .channel("*")
      .on(
        "postgres_changes",
        {
          schema: "public",
          table: "menu",
          event: "*",
        },
        (payload) => {
          setMenu((prevMenu) => {
            const updatedMenu = prevMenu.map((item) => {
              if (item.id === payload.new.id) {
                return payload.new;
              }
              return item;
            });
            return updatedMenu;
          });
        }
      )
      .subscribe();
    return () => {
      supabase.removeChannel(menuChannel);
    };
  }, [supabase]);

  const menuItems = showAll ? menu : menu.slice(0, 4);

  useEffect(() => {
    if (showAll) {
      setContainerHeight(`${menu.length * 3.5}em`);
    } else {
      setContainerHeight("8em");
    }
  }, [showAll, menu.length]);

  return (
    <div>
      <div className="available-container" style={{ height: containerHeight }}>
        {menuItems.map((item, index) => (
          <CountDisplay key={index} itemName={item.name} count={item.stock} stockLimit={item.stock_limit} />
        ))}
      </div>
      {menu.length > 3 && (
        <div className="expand-items" onClick={() => setShowAll(!showAll)}>
          {containerHeight === "8em" ? "Show all" : "Hide"} Items
          {containerHeight === "8em" ? (
            <TfiAngleDoubleDown className="dropdown-icon" />
          ) : (
            <TfiAngleDoubleUp className="dropdown-icon" />
          )}
        </div>
      )}
    </div>
  );
};

function Portal() {
  const { session } = useContext(SessionContext);

  if (!session) {
    window.location.href = "/";
  }

  const avatarInfo = session?.user;

  const navigate = useNavigate();

  const changePage = (page) => {
    setTimeout(() => {
      navigate(page);
    }, 200);
  };

  return (
    <div className="menu-screen productsans-regular">
      <div className="menu-screen-title">
        <span style={{ color: "#ffff" }} className="grifter-regular">
          MITS Canteen
        </span>
        <br />
        <span
          style={{ color: "#AEADAD", fontWeight: 100 }}
          className="poppins-regular"
        >
          Dining Redefined
        </span>
      </div>
      <ProfilePhoto avatarInfo={avatarInfo} />


      <AvailableItems />
      <div>
        <div
          className="ticketScanner portal-card"
          onClick={() => changePage("/scanner")}
        >
          <span>Ticket Scanner</span>
          <img src={Qrcode} />
        </div>
        <div
          className="orderHistory portal-card"
          onClick={() => changePage("/orders")}
        >
          <span>Order History</span>
          <img src={OrderIcon} />
        </div>
        <div
          className="orderHistory portal-card"
          onClick={() => changePage("/refund")}
        >
          <span>Refund</span>
          <img src={OrderIcon} />
        </div>
        <div
          className="orderHistory portal-card"
          onClick={() => changePage("/editmenu")}
        >
          <span>Edit Menu</span>
          <img src={MenuIcon} />
        </div>

        <div className="portal-sub-card">
          <div
            className="orderHistory portal-card "
            onClick={() => changePage("/users")}
          >
            <span>View Users</span>
            <img src={UserIcon} />
          </div>
          <div
            className="orderHistory portal-card"
            onClick={() => changePage("/statistics")}
          >
            <span>Statistics</span>
            <img src={Statistics} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portal;
